<template>
  <a-modal title='门店员工' :visible="visible" width="70%"
           @cancel="handleCancel"
           @ok="handleOk" cancelText='关闭'  >
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="昵称" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="手机号" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入手机号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="邀请码" prop="invitationCode">
                <a-input v-model="queryParam.invitationCode" placeholder="请输入邀请码" allow-clear/>
              </a-form-item>
            </a-col>

            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operations">
        <a-button type="primary" @click="$refs.storeUserForm.handleAdd(sysUserId)"  >
          <a-icon type="plus" />新增员工
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" >
          <a-icon type="delete" />删除
        </a-button>
      </div>
      <store-user-create-form ref='storeUserForm' @ok='getStoreUserList' />
      <store-user-info  ref='storeUserInfo' @ok='getStoreUserList' />
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <!--      -->
        <span slot="img" slot-scope="text,record">
          <img v-if='record.avatar' :src="record.avatar" style="width: 80px;height: 80px;"/>
           <span v-else>无</span>
        </span>
        <span slot="sex" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_user_sex']" :value="record.sex"/>
        </span>
        <span slot="isNotice" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_notice_status']" :value="record.isNotice"/>
        </span>
        <span slot="status" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_enable_disable']" :value="record.status"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>

        <span slot='operation' slot-scope='text, record'>
          <a @click="handleDetail(record)">详情</a>
          <a-divider type='vertical'  />
          <a @click='$refs.storeUserForm.handleUpdate(record, undefined)'
          >
            <a-icon type='edit' />修改
          </a>
          <a-divider type='vertical'  />
          <a @click='handleDelete(record)' >
            <a-icon type='delete' />删除
          </a>
        </span>
      </a-table>
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>

  </a-modal>
</template>

<script>

import { getStorePage, delUser,  } from '@/api/biz/user'
import StoreUserCreateForm from '@/views/store/modules/StoreUserCreateForm.vue'
import StoreUserInfo from '@/views/store/modules/StoreUserInfo.vue'
import { tableMixin } from '@/store/table-mixin'
export default {
  name: 'GraphCategory',
  components: {
    StoreUserCreateForm,StoreUserInfo
  },
  mixins: [tableMixin],
  dicts: ['sys_user_sex', 'sys_notice_status', 'sys_enable_disable'],
  data() {
    return {
      advanced:false,

      vipId:null,
      visible:false,
      multiple:false,
      list: [],
      // 展开的行，受控属性
      expandedRowKeys: [],
      replaceFields: {
        id: "id",
        pid: "pid",
        children: "childList"
      },
      sortParams: {
        field: "sort",
        way: "ASC"
      },
      selectedRowKeys: [],
      selectedRows: [],

      ids: [],
      loading: false,
      total: 0,
      sysUserId: null,
      // 查询参数
      queryParam: {
        nickname: null,
        sysUserId:null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '#',
          dataIndex: '',
          ellipsis: true,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '头像',
          dataIndex: 'avatar',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'img' }
        },
        {
          title: '性别',
          dataIndex: 'sex',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if(value == 1){
              return "男"
            }else if(value == 2){
              return "女"
            }
            return "保密"
          }
        },
        {
          title: '生日',
          dataIndex: 'birthday',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否会员',
          dataIndex: 'isVip',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if(value == 1){
              return "是"
            }
            return "否"
          }
        },
        {
          title: 'vip过期时间',
          dataIndex: 'vipExpTime',
          scopedSlots: { customRender: 'vipExpTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '邀请码',
          dataIndex: 'invitationCode',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '是否线下会员',
        //   dataIndex: 'isLineVip',
        //   ellipsis: true,
        //   align: 'center',
        //   customRender: function (value) {
        //     if(value == 1){
        //       return "是"
        //     }
        //     return "否"
        //   }
        // },
        // {
        //   title: '是否优质会员',
        //   dataIndex: 'isHighQuality',
        //   ellipsis: true,
        //   align: 'center',
        //   customRender: function (value) {
        //     if(value == 1){
        //       return "是"
        //     }
        //     return "否"
        //   }
        // },
        {
          title: '用户状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {

  },
  computed: {},
  watch: {},
  methods: {
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getStoreUserList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        mobile: undefined,
        email: undefined,
        nickname: undefined,
        sex: undefined,
        invitationCode: undefined,
        isNotice: undefined,
        status: undefined,
        sysUserId : this.sysUserId,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    show(record){
      this.selectedRowKeys = []
      this.selectedRows = []
      this.visible = true;
      this.multiple = false
      this.sysUserId = record.id;
      this.queryParam.sysUserId = record.id;

      this.getStoreUserList();

    },
    /** 查询引用列表 */
    getStoreUserList() {
      this.loading = true
      getStorePage(this.queryParam).then(response => {
        this.list = response.data.records;
        this.total = response.data.total
        this.loading = false
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    handleOk(){
      this.visible = false
      this.$emit('ok')
    },
    handleCancel(){
      this.visible = false
      this.$emit('ok')
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    handleDetail(rowData){
      this.$refs.storeUserInfo.initValue(rowData);
      this.$refs.storeUserInfo.title = "用户详情";
      this.$refs.storeUserInfo.visible = true;
    },
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      if(this.ids.length<1){
        that.$message.error(
          '请选择数据',
          3
        );
        return
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getStoreUserList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
  }
}
</script>
<style lang='less' scoped></style>